import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  max-width: ${(props) => props.theme.sizes.maxWidthCentered};
  margin: 0 auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
`

const ContainerWrapper = (props) => {
  return <Wrapper>{props.children}</Wrapper>
}

export default ContainerWrapper
