import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Container from '../components/Container'
import ContainerWrapper from '../components/ContainerWrapper'
import PageTitle from '../components/PageTitle'
import RetailerItem from '../components/RetailerItem'
import groupBy from 'lodash/groupBy'
import styled from 'styled-components'
import config from '../utils/siteConfig'

const Paragraph = styled.p`
  margin: 1rem 0 1rem 0;
  line-height: 1.5;
`

// const StoreIcon = styled.img`
//   width: 25px;
//   height: 25px;
//   background-color: #f3f3f3;
//   border-radius: 50%;
//   border: 2px solid #f3f3f3;
//   display: inline-block;
// `

// const CenterIcon = styled.span`
//   height: 100%;
//   margin: 0;
//   padding-right: 8px;
// `

const RetailerTemplate = ({ data, pageContext }) => {
  const { allCashback } = data

  const filteredBySlug = allCashback.edges
    .filter(
      ({ node }) =>
        pageContext.retailerSlug === node.slug &&
        node.cashback_number &&
        node.cashback_type
    )
    .map((item) => {
      return { ...item.node }
    })

  const groupedBySlug = groupBy(filteredBySlug, 'slug')

  const retailerData = (retailer) => {
    return groupedBySlug[retailer].map((item) => ({
      ...item,
      cashback_number: parseFloat(item.cashback_number),
      cashback_type: parseFloat(item.cashback_type),
    }))
  }

  const tableByRetailer = Object.keys(groupedBySlug).map((retailer, index) => {
    if (!retailer) {
      return
    }

    return (
      <RetailerItem
        retailerData={retailerData(retailer)}
        retailerSlug={pageContext.retailerSlug}
        key={index}
      />
    )
  })

  // <CenterIcon>
  //   <StoreIcon lazy src={filteredBySlug[0].store_icon_url} />
  // </CenterIcon>

  return (
    <Layout>
      <Helmet>
        <html lang={config.language} />

        <title lang={config.language}>
          Loja {pageContext.retailerName} - Cashback Ranking - Compare cashbacks com a nossa extensão
        </title>

        <meta charset="utf-8"></meta>
        <meta
          name="description"
          content={`Loja ${pageContext.retailerName} - Cashback Ranking - Compare os melhores cashbacks no Cashback Ranking: As melhores ofertas da internet!`}
        />
        <meta httpEquiv="content-language" content="pt-br" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1"></meta>
        <meta name="HandheldFriendly" content="True"></meta>
        <meta name="MobileOptimized" content="320"></meta>
        <link
          rel="canonical"
          href={`https://www.cashbackranking.com.br/loja/${pageContext.retailerSlug}`}
        ></link>
      </Helmet>

      <Container>
        <ContainerWrapper>
          <PageTitle>{pageContext.retailerName}</PageTitle>
          <Paragraph>
            Lembre-se: Ao acessar o link para o site de cashback efetue login ou
            crie sua conta antes de ir para o site da loja para garantir que sua
            compra será rastreada.
          </Paragraph>
          {tableByRetailer}
        </ContainerWrapper>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query RetailerQuery($retailerSlug: String!) {
    allCashback(filter: { slug: { eq: $retailerSlug } }) {
      edges {
        node {
          id
          cashback_type
          cashback_number
          cashback_id
          cashback
          name
          short_url
          site
          slug
          store_icon_url
          store_url
          url
        }
      }
    }
  }
`

export default RetailerTemplate
