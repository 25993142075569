import React from 'react'
import styled from 'styled-components'
import PureTable from '../components/PureTable'

import { orderedByCashbackNumber } from '../utils/dataOrganizer'

const importAll = (require) =>
  require.keys().reduce((acc, next) => {
    acc[next.replace('./', '')] = require(next)
    return acc
  }, {})

const icons = importAll(
  require.context('../images/site-icons', false, /\.(png|jpe?g|svg)$/)
)

const ImageIcon = styled.img`
  width: 13px;
  height: 13px;
  margin-right: 16px;
  padding: 0;
  border: 0;
  display: inline-block;
`

const CenteredSection = styled.section`
  margin: 0px auto;
`

const RetailerItem = ({ retailerData, retailerSlug }) => {
  const table = orderedByCashbackNumber(retailerData).map((store, index) => {
    return (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>
          <ImageIcon
            src={icons[`${store.site}.png`].default}
            alt={store.name}
          />
          <a href={store.url} target="_blank" rel="noreferrer">
            {store.site}
          </a>
        </td>
        <td>{store.cashback}</td>
      </tr>
    )
  })

  return (
    <CenteredSection id={retailerSlug}>
      <PureTable>
        <thead>
          <tr>
            <th>#</th>
            <th>Site</th>
            <th>Cashback</th>
          </tr>
        </thead>
        <tbody>{table}</tbody>
      </PureTable>
    </CenteredSection>
  )
}

export default RetailerItem
